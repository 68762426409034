.fade-enter {
  opacity: 0;
  transition: 0.5s;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
  transition: 0.5s;
}
.fade-exit-active {
  opacity: 0;
}

.appear-enter {
  transform: translateY(120%);
  transition: 0.5s;
  opacity: 0;
}
.appear-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.appear-exit {
  transform: translateY(0);
  transition: 0.5s;
  opacity: 1;
}
.appear-exit-active {
  transform: translateY(120%);
  opacity: 0;
}
