@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"), url(./Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"), url(./Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoMedium";
  src: local("RobotoMedium"), url(./Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"), url(./Roboto-Bold.ttf) format("truetype");
}
